<template>
  <!-- 详情与编辑、添加都放同一页 -->
  <div>
    <!-- 基本信息 -->
    <div class="pad-container" style="min-height: 700px">
      <p class="c-9">活动信息</p>
      <el-steps :active="presentStep" finish-status="success" simple>
        <el-step title="基本项设置"></el-step>
        <el-step title="活动项设置"></el-step>
        <el-step title="展示设置"></el-step>
        <el-step title="奖品设置"></el-step>
      </el-steps>
      <el-form
        v-if="presentStep == 0"
        class="mt-24"
        label-width="140px"
        :model="act_form"
        :rules="rules"
        ref="act_form"
      >
        <el-form-item label="活动名称" prop="name">
          <el-input
            class="form-input"
            maxlength="25"
            show-word-limit
            placeholder="请输入活动标题"
            v-model="act_form.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动时间" prop="startTime">
          <el-date-picker
            :disabled="isDisable"
            v-model="date"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            end-placeholder="结束日期"
            :picker-options="startDatePicker"
            @change="getDateTime($event, 0)"
            :default-time="['00:00:00','23:59:59']"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="单人每日限抽卡" prop="limitNumber">
          <el-input
            class="form-input"
            v-model.number="act_form.limitNumber"
            :disabled="isDisable"
          >
            <template slot="append">次</template>
          </el-input>
        </el-form-item>
        <el-form-item label="活动规则" prop="describeA">
          <el-input
            class="form-input"
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 8 }"
            maxlength="500"
            show-word-limit
            placeholder="请输入活动规则"
            v-model="act_form.describeA"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动主图" prop="activityPicture">
          <UploadPics
            :uploadType="'main'"
            :imgLimit="1"
            :imgSize="'700*450'"
            exceedInfo="活动主图"
            :imgsPreview="act_form.activityPicture"
            @getImgData="getActivityPictureData"
          />
        </el-form-item>
        <el-form-item label="分享图片" prop="shareImg">
          <UploadPics
            :uploadType="'main'"
            :imgLimit="1"
            :imgSize="'500*400'"
            exceedInfo="分享图片"
            :imgsPreview="act_form.shareImg"
            @getImgData="getShareImgData"
          />
        </el-form-item>
        <el-form-item label="分享标题" prop="shareTitle">
          <el-input
            class="form-input"
            v-model="act_form.shareTitle"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-form
        v-if="presentStep == 1"
        class="mt-24"
        label-width="100px"
        :model="card_form"
        :rules="cardRules"
        ref="card_form"
      >
        <el-button
          plain
          type="primary"
          class="form-button"
          @click="addCard"
          :disabled="
            (card_form.liriCardList && card_form.liriCardList.length >= 6) ||
              isDisable
          "
          >添加</el-button
        >
        <div
          v-for="(item, idx) in card_form.liriCardList"
          :key="idx"
          class="mt-20"
        >
          <el-row>
            <el-col :span="2" class="line-height">卡片{{ idx + 1 }}</el-col>
            <el-col :span="9">
              <el-form-item
                label="卡片名称"
                :prop="'liriCardList[' + idx + '].name'"
                :rules="{
                  validator: (x, y, z) => myValidate(x, y, z, 'name', idx),
                  required: true,
                  trigger: 'change',
                }"
              >
                <el-input
                  :disabled="isDisable"
                  :maxlength="5"
                  show-word-limit
                  placeholder="请输入卡片名称"
                  v-model="item.name"
                ></el-input>
                <p class="c-9 m-0">注：最多5个字，显示在已翻开卡片及文案中</p>
              </el-form-item>
            </el-col>
            <el-col :span="2" class="line-height" v-if="idx > 0 && !isDisable">
              <el-button
                type="danger"
                plain
                circle
                size="mini"
                icon="el-icon-minus"
                class="ml-15"
                @click="deleteCard(idx)"
              >
              </el-button>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="9" :offset="2">
              <el-form-item
                label="集到概率"
                :prop="'liriCardList[' + idx + '].probability'"
                :rules="[
                  { validator: myValidate, required: true, trigger: 'change' },
                ]"
              >
                <el-input
                  :disabled="isDisable"
                  placeholder="请输入获取概率(如：50)"
                  v-model.number="item.probability"
                >
                  <template slot="append">%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <el-divider></el-divider>
        <el-form-item
          label="设置分享条件"
          prop="limitCardNum"
          label-width="150px"
        >
          <el-row>
            <el-col :span="6">
              <el-select
                v-model="card_form.limitCardNum"
                placeholder="请选择限制卡片"
                @change="limitCardNumChange"
                :disabled="isDisable"
              >
                <el-option :value="0" label="无" />
                <el-option
                  v-for="(item, index) in card_form.liriCardList"
                  :key="index"
                  :label="'卡片' + (index + 1)"
                  :value="index + 1"
                >
                </el-option>
              </el-select>
            </el-col>
            <el-col class="c-9"
              >注：选择之后可以设置用户分享次数达到之后此卡片才出现下面设置的概率，如未达到概率为0，其他卡片也可重新设置概率</el-col
            >
          </el-row>
        </el-form-item>
        <el-row v-if="card_form.limitCardNum != ''" >
          <el-col :span="6">
            <el-form-item
              label-width="150px"
              label="用户分享次数达到"
              prop="limitNum"
              :rules="[
                { validator: myValidate, required: true, trigger: 'change' },
              ]"
              v-if="card_form.limitCardNum != ''"
            >
            <div style="width:200%;">
              <el-row>
                <el-col :span="10">
                  <el-input :disabled="isDisable" v-model="card_form.limitNum"
                    ><template slot="append">次</template>
                  </el-input>
                </el-col>
                <el-col :span="14">
                  时,以下卡片概率发生改变
                </el-col>
              </el-row>
              <el-row class="c-9"
              >注：仅针对分享次数达标的用户</el-row
            >
              </div>
            </el-form-item>
          </el-col>
        </el-row>
          
        <template v-if="card_form.limitCardNum != ''">
          <div
            v-for="(item, idx) in card_form.liriCardList"
            :key="idx + 10"
            class="mt-20"
          >
            <el-row>
              <el-col :span="2" class="line-height">卡片{{ idx + 1 }}</el-col>
              <el-col :span="9">
                <el-form-item label="卡片名称">
                  <span>{{ item.name }}</span>
                  <p class="c-9 m-0">注：最多5个字，显示在已翻开卡片及文案中</p>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="9" :offset="2">
                <el-form-item
                  label="集到概率"
                  :prop="'liriCardList[' + idx + '].probabilityTwo'"
                  :rules="[
                    {
                      validator: myValidate,
                      required: true,
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-input
                    :disabled="isDisable"
                    placeholder="请输入获取概率(如：50)"
                    v-model.number="item.probabilityTwo"
                  >
                    <template slot="append">%</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </template>
      </el-form>
      <el-form
        v-if="presentStep == 2"
        class="mt-24"
        label-width="100px"
        :model="card_form"
        ref="pic_form"
      >
        <div v-for="(item, idx) in card_form.liriCardList" :key="idx + 20">
          <el-form-item
            :label="'卡片' + (idx + 1)"
            :prop="'liriCardList[' + idx + '].picture'"
            :rules="{
              required: true,
              message: '卡片图片不能为空',
              trigger: 'blur',
            }"
          >
            <UploadPics
              :toEnable="!isDisable"
              :uploadType="'main'"
              :imgLimit="1"
              :imgSize="'160*200'"
              :params="{ index: idx }"
              :imgsPreview="item.picture"
              @getImgData="getImgData"
            />
          </el-form-item>
        </div>
      </el-form>
      <el-form
        v-if="presentStep >= 3"
        class="mt-24"
        label-width="100px"
        :model="act_form"
        :rules="rules"
        ref="prize_form"
      >
        <div class="prize ml-60">
          <el-form-item label="奖品属性" prop="prizeType">
            <div class="mb-10">
              <el-radio
                v-model="act_form.prizeType"
                :label="3"
                :disabled="isDisable"
                >实物奖品</el-radio
              >
              <div v-if="act_form.prizeType == 3" class="pad-container">
                <el-form-item prop="giftId">
                  <el-button
                    size="small"
                    class="ml-15"
                    type="primary"
                    :disabled="isDisable"
                    @click="choosePrize(goodsData)"
                    >{{
                      goodsData.length == 0 ? "选择商品" : "重新选择"
                    }}</el-button
                  >
                  <el-table
                    class="mt-24 member-table"
                    :data="goodsData"
                    v-if="goodsData.length > 0"
                  >
                    <template v-for="item in tableGoodsHeader">
                      <el-table-column
                        :label="item.value"
                        :key="item.key"
                        v-if="item.key !== 'operations'"
                      >
                        <template slot-scope="scope">
                          <el-avatar
                            v-if="item.key == 'imgUrl'"
                            :src="picsPath(scope.row.imgUrl)"
                            shape="square"
                            alt="加载失败"
                            :key="scope.row.imgUrl"
                          ></el-avatar>
                          <template v-else>{{ scope.row[item.key] }}</template>
                        </template>
                      </el-table-column>
                    </template>
                  </el-table>
                </el-form-item>
              </div>
            </div>
            <div>
              <el-radio
                v-model="act_form.prizeType"
                :label="1"
                :disabled="isDisable"
                >优惠券</el-radio
              >
              <div v-if="act_form.prizeType == 1" class="pad-container">
                <el-form-item prop="couponId">
                  <el-button
                    size="small"
                    class="ml-15"
                    type="primary"
                    :disabled="isDisable"
                    @click="chooseCoupon(cousData)"
                    >{{
                      cousData.length == 0 ? "选择优惠券" : "重新选择"
                    }}</el-button
                  >
                  <el-table
                    class="mt-24 member-table"
                    :data="cousData"
                    v-if="cousData.length > 0"
                  >
                    <template v-for="item in tableCouponHeader">
                      <el-table-column
                        :label="item.value"
                        :key="item.key"
                        v-if="item.key !== 'operations'"
                      >
                        <template slot-scope="scope">
                          <el-avatar
                            v-if="item.key == 'imgUrl'"
                            :src="picsPath(scope.row.imgUrl)"
                            shape="square"
                            alt="加载失败"
                            :key="scope.row.imgUrl"
                          ></el-avatar>
                          <template v-else-if="item.key == 'couponType'">
                            {{
                              scope.row.type == 0
                                ? "折扣券"
                                : scope.row.type == 1
                                ? "代金券"
                                : scope.row.type == 2
                                ? "兑换券"
                                : scope.row.type == 3
                                ? "停车券"
                                : ""
                            }}
                          </template>
                          <template v-else-if="item.key == 'coupon'">
                            {{ couponType[scope.row.coupon] }}
                          </template>
                          <template v-else-if="item.key == 'drawCondition'">
                            {{
                              scope.row.drawCondition == 1 ? "免费领取" : "限领"
                            }}
                          </template>
                          <template v-else>{{ scope.row[item.key] }}</template>
                        </template>
                      </el-table-column>
                    </template>
                  </el-table>
                </el-form-item>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="奖品说明" prop="prizeDescribe">
            <el-input
              :disabled="isDisable"
              class="form-input"
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 8 }"
              maxlength="500"
              show-word-limit
              placeholder="请输入奖品说明"
              v-model="act_form.prizeDescribe"
            ></el-input>
          </el-form-item>
          <el-form-item label="奖品有效期" prop="prizeInvalidRule">
            <div>
              <el-row>
                <el-col :span="2"
                  ><el-radio
                    v-model="act_form.prizeInvalidRule"
                    :label="1"
                    :disabled="isDisable"
                    >固定日期</el-radio
                  ></el-col
                >
                <el-col :span="16">
                  <el-form-item
                    prop="prizeStartTime"
                    v-if="act_form.prizeInvalidRule == 1"
                  >
                    <el-date-picker
                      :disabled="isDisable"
                      v-model="date2"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      end-placeholder="结束日期"
                      @change="getDateTime($event, 1)"
                      :default-time="['00:00:00','23:59:59']"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div class="mt-15">
              <el-row>
                <el-col :span="2"
                  ><el-radio
                    v-model="act_form.prizeInvalidRule"
                    :label="2"
                    :disabled="isDisable"
                    >特殊日期</el-radio
                  ></el-col
                >
                <el-col :span="6" v-if="act_form.prizeInvalidRule == 2">
                  <el-row>
                    <el-form-item prop="prizeStartDay">
                      <span>领取后</span>
                      <el-input
                        :disabled="isDisable"
                        class="search-input"
                        v-model="act_form.prizeStartDay"
                      >
                        <template slot="append">天</template>
                      </el-input>
                    </el-form-item>
                  </el-row>
                </el-col>
                <el-col :span="6" v-if="act_form.prizeInvalidRule == 2">
                  <el-row>
                    <el-form-item prop="prizeEndDay">
                      <span>生效，有效天数</span>
                      <el-input
                        :disabled="isDisable"
                        class="search-input"
                        v-model="act_form.prizeEndDay"
                      >
                        <template slot="append">天</template>
                      </el-input>
                    </el-form-item>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="flx-row ali-c js-fe mt-50">
      <el-button plain class="form-button" @click="goPrevStep">{{
        presentStep > 0 ? "上一步" : "取消"
      }}</el-button>
      <el-button
        class="form-button"
        type="primary"
        @click="goNextStep"
        v-if="presentStep < 3"
        >下一步</el-button
      >
      <el-button class="form-button" type="primary" @click="confirm" v-else
        >提交</el-button
      >
    </div>
    <!-- 优惠券弹窗 -->
    <DepartmentGoodsDialog
      ref="CouponDialog"
      :tableHeader="tableCouponHeader"
      @getSelectedList="getSelectedCouponList"
      @getTableList="getTableCouponList"
      :searchArr="searchArr"
      keyId="couponId"
      :selected="false"
    ></DepartmentGoodsDialog>
    <!-- 商品弹窗 -->
    <DepartmentGoodsDialog
      ref="GoodsDialog"
      :tableHeader="tableGoodsHeader"
      @getSelectedList="getSelectedGoodsList"
      @getTableList="getTableGoodsList"
      :selected="false"
    ></DepartmentGoodsDialog>
  </div>
</template>
<script>
import Tinymce from "@/components/Tinymce";
import DepartmentGoodsDialog from "@/components/DepartmentGoodsDialog";
import { getCouponList, getCouponDetail } from "@/api/maketing/coupons";
import {
  getProcudtList,
  getProductDetail,
} from "@/api/online_department_store/commodity_manage";
import {
  subCollectingForm,
  getCollectingDetail,
} from "@/api/maketing/collecting";
import UploadPics from "@/components/UploadPics";
export default {
  components: {
    Tinymce,
    UploadPics,
    DepartmentGoodsDialog,
  },
  data() {
    let that = this;
    let myValidate = (rule, value, callback, src, index) => {
      if (src == "name") {
        if (value === undefined || value === null || value === "") {
          callback(new Error("不能为空"));
        } else if (
          that.isRepeat(
            that.card_form.liriCardList.map((ele) => ele.name),
            index
          )
        ) {
          callback(new Error("卡片名字不能重复"));
        } else {
          callback();
        }
      } else {
        if (!/^[0-9]\d*$/.test(value)) {
          callback(new Error("整数"));
        } else if (value === undefined || value === null || value === "") {
          callback(new Error("不能为空"));
        } else {
          callback();
        }
      }
    };
    return {
      date: [], //日期时间
      date2: [], //活动奖品有效时间
      act_form: {
        prizeInvalidRule: 1, //奖品失效规则
        prizeStartDay: null, //奖品生效日
        prizeEndDay: null, //奖品失效日
        goodsAmount: 1000, //!奖品数量 一期暂定1000
        prizeType: 3, //奖品类型
        name: "", //活动名称
        activityPicture: "", //活动主图
        shareImg: "", //活动分享图
        shareTitle: "", //活动分享标题
        limitNumber: "", //限制数量
        describeA: "", //活动描述
        giftId: null, //奖品商品id
        couponId: null, //奖品优惠券id
        status: 2, //活动默认状态
        prizeDescribe: "", //奖品描述
        startTime: "", //活动开始时间
        endTime: "", //活动结束时间
        prizeStartTime: "",
        prizeEndTime: "",
      },
      goodsData: [], //已选择的商品表格
      cousData: [], //已选择的优惠券表格
      presentStep: 0, //当前步骤
      card_form: {
        limitCardNum: 0,
        limitNum: "",
        liriCardList: [
          {
            name: "", //卡片名称
            probability: null, //卡片概率
            picture: "", //卡片filename
            probabilityTwo: "", //卡片限制概率
          },
        ],
      },
      rules: {
        name: [
          { required: true, message: "活动名称不能为空", trigger: "blur" },
        ],
        startTime: [
          { required: true, message: "活动时间不能为空", trigger: "blur" },
        ],
        limitNumber: [
          { required: true, message: "抽卡不能为空", trigger: "blur" },
          {
            type: "number",
            message: "只能为非负整数",
            min: 0,
            trigger: "blur",
          },
        ],
        describeA: [
          { required: true, message: "活动规格不能为空", trigger: "blur" },
        ],
        activityPicture: [
          { required: true, message: "活动主图不能为空", trigger: "blur" },
        ],
        shareImg: [
          { required: true, message: "活动分享图不能为空", trigger: "blur" },
        ],
        shareTitle: [
          { required: true, message: "活动分享标题不能为空", trigger: "blur" },
        ],
        prizeType: [
          { required: true, message: "奖品属性不能为空", trigger: "blur" },
        ],
        prizeStartTime: [
          { required: true, message: "固定日期不能为空", trigger: "blur" },
        ],
        giftId: [{ required: true, message: "奖品不能为空", trigger: "blur" }],
        couponId: [
          { required: true, message: "优惠券不能为空", trigger: "blur" },
        ],
        prizeDescribe: [
          { required: true, message: "奖品说明不能为空", trigger: "blur" },
        ],
        prizeInvalidRule: [
          { required: true, message: "奖品失效规则不能为空", trigger: "blur" },
        ],
        prizeStartDay: [
          { required: true, message: "奖品生效日不能为空", trigger: "blur" },
        ],
        prizeEndDay: [
          { required: true, message: "奖品失效日不能为空", trigger: "blur" },
        ],
      },
      cardRules: {
        limitCardNum: [
          { required: false, message: "分享条件不能为空", trigger: "blur" },
        ],
      },
      tableGoodsHeader: [
        // 商品列表表头
        {
          value: "商品条形码",
          key: "barCode",
        },
        {
          value: "商品名称",
          key: "goodsName",
        },
        {
          value: "商品图片",
          key: "imgUrl",
        },
         {
          value: "所属门店",
          key: "storeName",
        },
        {
          value: "一级分类",
          key: "firstClassifyName",
        },
        {
          value: "二级分类",
          key: "secondClassifyName",
        },
        {
          value: "实价",
          key: "price",
        },
        {
          value: "库存",
          key: "stock",
        },
        {
          value: "操作",
          key: "operations",
        },
      ],
      tableCouponHeader: [
        // 优惠券列表表头
        {
          value: "优惠券名称",
          key: "name",
        },
        {
          value: "优惠券类型",
          key: "coupon",
        },
        {
          value: "卡券有效期",
          key: "validTime",
        },
        {
          value: "领取条件",
          key: "drawCondition",
        },
        {
          value: "库存数量",
          key: "stock",
        },
        {
          value: "每人限领",
          key: "limitAmount",
        },
        {
          value: "领取数量",
          key: "received",
        },
        {
          value: "操作",
          key: "operations",
        },
      ],
      searchArr: [
        {
          name: "searchKey",
          value: "",
          placeholder: "请输入关键字",
        },
        {
          name: "couponStatus",
          value: "",
          placeholder: "请选择状态",
        },
      ],
      couponType: [
        "",
        "通用劵",
        "商品劵",
        "餐饮优惠券",
        "折扣券",
        "代金券",
        "停车券",
      ],
      isDisable: false,
      myValidate: myValidate,
      startDatePicker: this.beginDate(),
    };
  },
  mounted() {
    this.collectionId = this.$route.query.id;
    if (this.collectionId && this.collectionId != "undefined")
      this.getCardsData(this.collectionId);
  },
  methods: {
    beginDate() {
      return {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000; //开始时间不选时，结束时间最大值小于等于当天
        },
      };
    },
    limitCardNumChange() {
      //限制卡片选择改变
      // console.log(this.card_form.limitCardNum);
    },
    getImgData(imgData, type, params) {
      //卡片图片数据回填
      let idx = params && params.index;
      if (type == "main")
        this.card_form.liriCardList[idx].picture = imgData.mainImg;
    },
    isRepeat(arr, index) {
      //判断卡片是否重复名称
      // // console.log(arr)
      let hash = {};
      for (let i in arr) {
        if (hash[arr[i]]) {
          return i == index;
        }
        // 不存在该元素，则赋值为true
        hash[arr[i]] = true;
      }
      return false;
    },
    getActivityPictureData(imgData) {
      //活动图片数据回填
      this.act_form.activityPicture = imgData.mainImg;
    },
    getShareImgData(imgData) {
      //分享图片数据回填
      this.act_form.shareImg = imgData.mainImg;
    },
    getCardsData(id) {
      //获取集卡活动数据
      getCollectingDetail(id).then((res) => {
        let resData = JSON.parse(JSON.stringify(res.data.body));
        this.act_form = { ...resData };
        this.isDisable = resData.isEdit == 1 ? false : true;
        this.card_form.limitNum = resData.shareTime;
        this.card_form.limitCardNum = resData.shareCardIndex;
        this.card_form.liriCardList = resData.liriCardList;
        this.date = [resData.startTime, resData.endTime];
        this.date2 = [resData.prizeStartTime, resData.prizeEndTime];
        if (resData.prizeType == 3) this.getGiftData(resData.giftId);
        if (resData.prizeType == 1) this.getCouponData(resData.couponId);
      });
    },
    getDateTime(e, type) {
      //获取时间
      // console.log(this.date);
      // console.log(e, type);
      if (type == 0) this.act_form.startTime = e && e[0];
      if (type == 1) this.act_form.prizeStartTime = e && e[0];
    },
    //最后一步  点击确定

    confirm() {
      // 提交
      // console.log(this.act_form)
      this.$refs["prize_form"].validate((valid) => {
        // console.log(valid)
        if (!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: "warning",
          });
          return;
        } else {
          let data = JSON.parse(JSON.stringify(this.act_form));
          data.startTime = this.date[0];
          data.endTime = this.date[1];
          data.prizeStartTime = this.date2[0];
          data.prizeEndTime = this.date2[1];
          data.liriCardList = this.card_form.liriCardList;
          data.shareTime = this.card_form.limitNum;
          data.shareCardIndex = this.card_form.limitCardNum;
          // console.log(data);
          subCollectingForm(data, !this.collectionId ? 1 : 0).then((res) => {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 3 * 1000,
            });
            this.$router.go(-1);
          });
        }
        // console.log("step4", this.presentStep);
      });
    },
    goNextStep(e) {
      // 下一步
      if (this.isDisable) {
        this.presentStep++;
      } else {
        if (this.presentStep == 0) {
          this.$refs["act_form"].validate((valid) => {
            if (!valid) {
              this.$message({
                message: "表单信息不完整（有误）",
                type: "warning",
              });
              return;
            } else {
              setTimeout(() => this.presentStep++, 100);
            }
            // // console.log('step0', this.presentStep)
          });
        }

        if (this.presentStep == 1) {
          this.$refs["card_form"].validate((valid) => {
            if (!valid) {
              this.$message({
                message: "表单信息不完整（有误）",
                type: "warning",
              });
              return;
            } else {
              setTimeout(() => this.presentStep++, 100);
            }
            // // console.log('step1', this.presentStep)
          });
        }

        if (this.presentStep == 2) {
          this.$refs["pic_form"].validate((valid) => {
            if (!valid) {
              this.$message({
                message: "表单信息不完整（有误）",
                type: "warning",
              });
              return;
            } else {
              setTimeout(() => this.presentStep++, 100);
            }
            // // console.log('step2', this.presentStep)
          });
        }
      }
    },
    goPrevStep(e) {
      //上一步
      if (this.presentStep > 0) {
        this.presentStep--;
      } else {
        this.presentStep = 0;
        this.$router.push({
          name: "Collecting_list",
        });
      }
    },
    addCard(e) {
      //添加卡片
      if (this.card_form.liriCardList.length >= 6) return;
      this.$set(
        this.card_form.liriCardList,
        this.card_form.liriCardList.length,
        {
          name: "",
          probability: "",
          picture: "",
          probabilityTwo: "",
        }
      );
    },
    deleteCard(idx) {
      //移除卡片
      this.card_form.liriCardList.splice(idx, 1);
    },
    getGiftData(id) {
      //获取选择的商品详情
      if (!id) return;
      getProductDetail(id).then((res) => {
        this.goodsData = [res.data.body];
      });
    },
    getCouponData(id) {
      //获取选择的优惠券详情
      if (!id) return;
      getCouponDetail(id).then((res) => {
        this.cousData = [res.data.body];
      });
    },
    getSelectedGoodsList(data, index) {
      //获取已选商品
      // console.log(data)
      this.act_form.couponId = null;
      this.cousData = [];
      this.act_form.giftId = data.goodsId;
      this.act_form.goodsAmount = data.stock;
      this.goodsData = [{ ...data }];
    },
    getTableGoodsList(data) {
      //根据搜索条件改变列表
      getProcudtList({ goodsStatus: 1, ...data }).then((res) => {
        let data = this.$refs.GoodsDialog;
        data.total = res.data.body.total;
        data.tableData = res.data.body.list;
      });
    },
    choosePrize(products) {
      // 打开商品列表弹窗
      // // console.log(products, "list");
      let data = this.$refs.GoodsDialog;
      data.act_form.list = products;
      data.selected_temp = JSON.parse(JSON.stringify(products));
      data.showGoods = true;
      data.getGoods(false);
    },
    getSelectedCouponList(coupons, index) {
      //获取已勾选优惠券
      // console.log("coupons",coupons)
      this.act_form.couponId = coupons.couponId;
      this.act_form.giftId = null;
      this.goodsData = [];
      this.act_form.goodsAmount = coupons.stock;
      this.cousData = [{ ...coupons }];
    },
    getTableCouponList(data) {
      //获取优惠券列表
      let params = { ...data };
      params.status = params.couponStatus;
      delete params.couponStatus;
      getCouponList(params).then((res) => {
        let data = this.$refs.CouponDialog;
        data.total = res.data.body.total;
        data.tableData = res.data.body.list;
      });
    },
    chooseCoupon(coupons) {
      // 打开优惠券列表弹窗
      // // console.log(coupons, "coupons");
      let data = this.$refs.CouponDialog;
      data.act_form.list = coupons;
      data.selected_temp = JSON.parse(JSON.stringify(coupons));
      data.showGoods = true;
      data.getGoods(true);
    },
  },
};
</script>
<style lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
<style lang="scss" scoped>
.el-icon-delete-solid:hover {
  color: #f56c6c;
  cursor: pointer;
}
.card,
.prize {
  counter-increment: cardNum;
}
.card::before {
  content: "卡片 " counter(cardNum);
  display: block;
  color: #606266;
  transform: translate(-60px, 30px);
}
.prize::before {
  content: "奖品";
  display: block;
  color: #606266;
  transform: translate(-60px, 30px);
}
.probability::after {
  content: "%";
  display: inline-block;
  font-size: 24px;
  color: #999;
  transform: translate(470px, -40px);
}
.tip-text-info {
  color: #409eff;
  cursor: pointer;
}
.line-height {
  line-height: 40px;
}
</style>
